import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Link } from '@mui/material';
import logo from '../images/logo.png'

export function Header(){
    return <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ bgcolor: "white" }}>
					<Toolbar>
						<Box sx={{ flexGrow: 1}}>
							<Link href='/' sx={{verticalAlign: 'center'}}>
								<img src={logo} height={50} />
							</Link>
						</Box>
					</Toolbar>
        </AppBar>
    </Box>
}
