import { Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import ReactMarkdown from 'react-markdown';
import { QuestionDetails } from "../models/questionModel";


export interface QuestionTabProps {
  questionDetails: QuestionDetails;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    padding: {
      padding: theme.spacing(2),
    },
    paper: {
      //height: "80%",
      height: "100%",
      padding: theme.spacing(2),
    }
  }),
);



export const QuestionTabPanel = (props: QuestionTabProps) => {
  const { questionDetails } = props;
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const classes = useStyles();
  const handlePanelChange =
  (panel: boolean) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [value, setValue] = React.useState("**Hello world!!!**");

  return (
    <React.Fragment>
      <Paper className={classes.paper}>

        <ReactMarkdown>
          {questionDetails.body}
        </ReactMarkdown>
        <Stack direction="column" spacing={[0, 4]}>
          <Typography variant="h5">
            {questionDetails.id}. {questionDetails.title}
          </Typography>
          <Typography variant="body1">
          </Typography>
        </Stack>
      </Paper>

    </React.Fragment>
  );
};
